<template>
  <v-btn :type="type" :color="color" :class="classes" @click="handleClick">
    {{ label }}
  </v-btn>
</template>

<script>
import "./button.scss";

export default {
  name: "RButton",

  props: {
    addclasses: {
      type: String,
      required: false,
    },
    onClick: {
      type: Function,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    main: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: function (value) {
        return ["small", "medium", "large"].indexOf(value) !== -1;
      },
    },
  },

  computed: {
    classes() {
      return {
        "r-btn": true,
        [`${this.size || "medium"}`]: true,
        [`${this.addclasses}`]: this.addclasses,
      };
    },
    type() {
      return this.submit ? "submit" : "button"
    }
  },
  methods: {
    handleClick() {
      if (this.onClick) {
        this.onClick()
      }
    }
  }
};
</script>
