<template>
  <div class="restore">
    <div class="restore-bg">
      <v-img
        :src="require('../../assets/logo-one-letter.svg')"
        contain
        height="280"
      />
    </div>
    <div class="restore-form">
      <div class="wrap px-6">
        <v-img
          :src="require('../../assets/rosplatforma.svg')"
          contain
          height="72"
        />
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="validate"
        >
          <r-text color="muted" text="E-mail" addclasses="fs-2 mb-2" />

          <v-text-field
            :rules="emailRules"
            v-model="username"
            outlined
          ></v-text-field>
          <div v-if="codeField">
            <r-text
              color="muted"
              text="Код восстановления"
              addclasses="fs-2 mb-2"
            />
            <v-text-field v-model="code" outlined></v-text-field>
          </div>
          <div class="d-flex" v-if="codeField == false">
            <RButton
              addclasses="c-bg-secondary"
              label="Отправить код"
              main
              :onClick="validate"
              size="small"
              submit
            />
            <div class="d-flex align-center">
              <router-link class="ml-2 register-link" to="/login"
                >Отмена</router-link
              >
            </div>
          </div>
          <div class="d-flex" v-else>
            <RButton
              addclasses="c-bg-secondary"
              label="Обновить пароль"
              main
              :onClick="sendNewPasswordRequest"
              size="small"
              submit
            />
            <div class="d-flex align-center">
              <router-link class="ml-2 register-link" to="/login"
                >Отмена</router-link
              >
            </div>
          </div>
        </v-form>
        <v-snackbar v-model="resNotify">
          Отправили новый пароль на почту

          <template v-slot:action="{}">
            <button class="link mr-1" @click="toLogin">ОК</button>
          </template>
        </v-snackbar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import RText from "../ui/Text/Text.vue";
import RButton from "../ui/Button/Button.vue";
import api from "../../api";

export default {
  name: "Restore",
  components: { RText, RButton },
  props: {},
  computed: mapState({
    username: (state) => state.user.username,
  }),
  data: () => ({
    resNotify: false,
    codeField: false,
    valid: true,
    code: "",
    emailRules: [
      (v) => !!v || "Введите e-mail",
      (v) => /.+@.+\..+/.test(v) || "Введите корректный e-mail",
    ],
  }),
  methods: {
    ...mapActions("user", ["restore"]),
    restorePassword() {
      const body = JSON.stringify({ email: this.username });
      api.sendPasswordReset(body).then(() => {
        this.codeField = true;
      });
    },
    validate() {
      const isValid = this.$refs.form.validate();
      return isValid;
    },
    sendNewPasswordRequest() {
      const body = JSON.stringify({
        email: this.username,
        recovery_code: this.code,
      });
      api.recoverPasswordWithCode(body).then(() => {
        this.resNotify = true;
      });
    },
    toLogin() {
      this.resNotify = false;
      this.$router.push("/login");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
<style>
.restore {
  display: grid;
  grid-template-columns: 1fr 512px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
}

.restore-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.restore-form {
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.176);
  background: var(--light);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.wrap {
  width: 100%;
}

.restore-link {
  color: #717378 !important;
}

.register-link {
  color: #2d3138 !important;
}
</style>