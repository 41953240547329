import Vue from "vue";
import api from "../../api";

// initial state
const state = () => ({
  info: null,
  username: "",
  password: "",
  error: false,
});

// getters
const getters = {};

// actions
const actions = {
  async login({ state, commit }) {
    const loginResponse = await api
      .login({ username: state.username, password: state.password })
      .catch(() => {
        commit("setErrorNotification", true);
        setTimeout(() => commit("setErrorNotification", false), 3000);
      });
    if (loginResponse.status.severity != "info") {
      commit("setErrorNotification", true);
    } else {
      Vue.$cookies.set("token", loginResponse.payload.token);
      commit("setUserInfo", loginResponse.payload);
    }
  },

  async logout({ commit }) {
    commit("logOut");
  },
  async closeNotification({ commit }) {
    commit("setErrorNotification", false);
  },
};

// mutations
const mutations = {
  setUserInfo(state, userInfo) {
    state.info = userInfo;
  },
  logOut(state) {
    Vue.$cookies.remove("token");
    state.info = null;
    state.username = "";
    state.password = "";
  },
  updateUsername(state, username) {
    state.username = username;
  },

  updatePassword(state, password) {
    state.password = password;
  },
  setErrorNotification(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
