import Vue from 'vue'
import VueRouter from "vue-router"
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import Dashboard from './components/pages/Dashboard.vue'
import Login from './components/pages/Login.vue'
import Registration from './components/pages/Registration.vue'
import Restore from './components/pages/Restore.vue'
import './assets/fonts/fa.css'

Vue.use(VueRouter);
Vue.use(store);

const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: "/registration",
    component: Registration
  },
  {
    path: "/restore",
    component: Restore
  },
  {
    path: "/",
    redirect: () => {
      
      return '/login'
    },
  }
];
const router = new VueRouter({
  routes,
  mode: "history",
});

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
