<template>
  <div class="login">
    <div class="login-bg">
      <v-img
        :src="require('../../assets/logo-one-letter.svg')"
        contain
        height="280"
      />
    </div>
    <div class="login-form">
      <div class="wrap px-6">
        <v-img
          :src="require('../../assets/rosplatforma.svg')"
          contain
          height="72"
        />
        <form @submit.prevent="login">
          <r-text color="muted" text="Логин" addclasses="fs-2 mt-4 mb-2" />
          <v-text-field v-model="username" outlined></v-text-field>
          <div class="d-flex justify-space-between mb-2">
            <r-text color="muted" text="Пароль" addclasses="fs-2" />
            <!-- <router-link class="restore-link" to="/restore"
              >Восстановить пароль</router-link
            > -->
          </div>
          <v-text-field
            v-model="password"
            type="password"
            outlined
          ></v-text-field>
          <div class="d-flex">
            <RButton
              label="Войти"
              color="grey darken-2"
              addclasses="mr-2 white--text"
              size="small"
              submit
              :onClick="login"
            />
            <div class="d-flex align-center">
              <r-text
                color="dark-active"
                text="Нет аккаунта? "
                addclasses="mr-2 ml-4"
              />

              <router-link class="register-link" to="/registration"
                >Зарегистрируйтесь</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </div>
    <v-snackbar timeout="-1" v-model="error">
      Неправильный логин или пароль

      <template v-slot:action="{}">
        <button class="link mr-1" @click="closeNotification">
          <v-icon>mdi-close</v-icon>
        </button>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import RText from "../ui/Text/Text.vue";
import RButton from "../ui/Button/Button.vue";

export default {
  name: "Login",
  components: { RText, RButton },
  props: {},
  computed: {
    ...mapState({
      error: (state) => state.user.error,
    }),
    console: () => console,
    ...{
      username: {
        get() {
          return this.$store.state.username;
        },
        set(value) {
          this.$store.commit("user/updateUsername", value);
        },
      },

      password: {
        get() {
          return this.$store.state.password;
        },
        set(value) {
          this.$store.commit("user/updatePassword", value);
        },
      },
    },
  },
  methods: {
    ...mapActions("user", ["login", "closeNotification"]),
  },
};
</script>
<style>
.login {
  display: grid;
  grid-template-columns: 1fr 512px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
}

.login-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-form {
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.176);
  background: var(--light);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.wrap {
  width: 100%;
}

.restore-link {
  color: #717378 !important;
}

.register-link {
  color: #2d3138 !important;
}
</style>