<template>
  <div class="wrapper">
    <div class="header">
      <v-container>
        <img :src="require('../../assets/rosplatforma.svg')" height="46" />

        <button class="link" @click="tryLogout">Выйти</button>
      </v-container>
    </div>
    <div class="app-body pt-16">
      <v-container>
        <v-data-table
          :headers="headers"
          :items="items"
          :hide-default-footer="items && items.length < 6"
          :footer-props="{
            pageText: '{0}-{1} из {2}',
            'items-per-page-text': 'строк',
            'items-per-page-options': [5, 10],
          }"
          class="nobg"
        >
          <template v-slot:no-data> Пока запросов на лицензии нет </template>
          <template v-slot:item="{ item }">
            <template
              v-if="item.state === 'requested' || item.state === 'REQUESTED'"
            >
              <tr class="centered">
                <td>{{ typeName(item.key_type) }}</td>
                <td>{{ productName(item.product) }}</td>
                <td colspan="3" class="status-column disabled">
                  {{ statusName(item.state) }}
                </td>
              </tr>
            </template>
            <template
              v-if="item.state === 'cancelled' || item.state === 'CANCELLED'"
            >
              <tr class="centered">
                <td>{{ typeName(item.key_type) }}</td>
                <td>{{ productName(item.product) }}</td>
                <td colspan="3" class="status-column disabled">
                  {{ statusName(item.state) }}
                </td>
              </tr>
            </template>
            <template
              v-if="item.state === 'creation' || item.state === 'CREATION'"
            >
              <tr class="centered">
                <td>{{ typeName(item.key_type) }}</td>
                <td>{{ productName(item.product) }}</td>
                <td colspan="3" class="status-column active">
                  {{ statusName(item.state) }}
                </td>
              </tr>
            </template>
            <template
              v-if="item.state === 'PRESENT' || item.state === 'present'"
            >
              <tr class="centered" @click="handleRowClick(item)">
                <td>{{ typeName(item.key_type) }}</td>
                <td>{{ productName(item.product) }}</td>
                <td>{{ dateFormat(item.created_at) }}</td>
                <td>{{ dateFormat(item.expired_at) }}</td>
                <td class="active">
                  {{ statusName(item.state) }}
                  <v-dialog
                    :retain-focus="false"
                    v-model="licDialog"
                    width="700"
                  >
                    <template v-slot:activator="{}"> </template>

                    <v-card>
                      <v-card-title class="text-h5 lighten-2 d-flex">
                        <div class="text-h5">Ключ лицензии</div>
                        <v-btn color="grey darken-2" class="ml-4 white--text" @click="copyToBuffer(lic)">Скопировать</v-btn>
                      </v-card-title>
                      <v-card-text class="mt-2">
                        <v-textarea
                          auto-grow
                          readonly
                          class="license-text"
                          name="lic-textarea"
                          :value="lic"
                        ></v-textarea>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </td>
              </tr>
            </template>
          </template>
        </v-data-table>
        <v-dialog v-model="create" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" class="mt-2" v-on="on">
              <span class="fs-10 lighter">+</span>
              <span class="fs-2 ml-2">Новая лицензия</span>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="fs-4 lighten-2">
              Запрос на новую лицензию
            </v-card-title>

            <v-card-text>
              <v-row class="d-flex align-center">
                <v-col>
                  <div class="fs-2 ml-2 modal-heading">Тип лицензии</div>
                </v-col>
                <v-col>
                  <v-select
                    v-model="lType"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    :items="types"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center">
                <v-col>
                  <div class="fs-2 ml-2 modal-heading">Продукт</div>
                </v-col>
                <v-col>
                  <v-select
                    v-model="lProd"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    :items="products"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="d-flex justify-end">
              <RButton
                label="Отправить"
                :onClick="sendLicRequest"
                color="grey darken-2"
                addclasses="mr-2 white--text"
                size="small"
              />
              <RButton
                label="Отмена"
                :onClick="close"
                size="small"
              />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
    <v-snackbar v-model="snackbar">
      Запрос на лицензию отправлен

      <template v-slot:action="{}">
        <button class="link mr-1" @click="snackbar = false">ОК</button>
      </template>
    </v-snackbar>
    <footer class="footer">
      <div class="c-dark">© ООО Р-Платформа</div>
    </footer>
  </div>
</template>

<script>
import moment from "moment";
import RButton from "../ui/Button/Button.vue";

import { mapState, mapActions } from "vuex";
import api from "../../api";

export default {
  name: "Dashboard",
  components: { RButton },

  props: {},

  computed: {
    types() {
      const hasCommercial = process.env.COMMERCIAL;
      const allTypes = [
        { value: "trial", name: "Пробная" },
      ];
      return hasCommercial ? [{ value: "production", name: "Официальная" }, ...allTypes] : allTypes;
    },
    ...mapState({
      items: (state) => state.licensions.list,
    }),
  },
  data: () => ({
    headers: [
      { text: "Тип", value: "type" },

      {
        text: "Продукт",
        sortable: false,
        value: "product",
      },
      { text: "Создан", value: "created_at" },
      { text: "Истекает", value: "expired_at" },
      { text: "Статус", value: "version" },
    ],
    dialog: false,
    create: false,
    products: [
      { name: "Р-Хранилище", value: "PCSS" },
      { name: "Р-Виртуализация", value: "RVZ7" },
    ],
    lType: "",
    lProd: "",
    snackbar: false,
    licDialog: false,
    lic: null,
  }),

  methods: {
    ...mapActions("user", ["logout"]),
    ...mapActions("licensions", ["getList", "getLicense"]),
    dateFormat(dateStr) {
      return moment(dateStr).format("DD-MM-YYYY");
    },
    tryLogout() {
      this.logout()
      this.$router.push('/login')
    },
    copyToBuffer(text) {
      navigator.clipboard.writeText(text);
    },
    statusName(name) {
      const statusNames = {
        REQUESTED: "Запрос отправлен",
        CREATION: "Генерация",
        PRESENT: "Просмотр",
        CANCELLED: "Запрос отменён",
        requested: "Запрос отправлен",
        creation: "Генерация",
        present: "Просмотр",
        cancelled: "Запрос отменён"
      };
      return statusNames[name];
    },
    productName(name) {
      const products = {
        PCSS: "Р-Хранилище",
        RVZ7: "Р-Виртуализация",
      };
      return products[name];
    },
    typeName(name) {
      const types = {
        trial: "Пробная",
        commercial: "Официальная",
      };
      return types[name];
    },
    close() {
      this.create = false;
    },
    handleRowClick(row) {
      const { id } = row;
      api.getLicenseInfo(id).then((res) => {
        const info = res.payload.license;
        (this.lic = info), (this.licDialog = true);
      });
    },
    async sendLicRequest() {
      const licData = JSON.stringify({
        key_type: this.lType,
        product: this.lProd,
      });
      await api.createLic(licData).then(() => {
        this.snackbar = true;
        this.create = false;
        this.getList();
      });
    },
  },

  created() {
    const isAuthorized = this.$cookies.get("token");
    if (isAuthorized === null) {
      this.$router.push("/login");
    }
    this.getList();
  },
};
</script>
<style>
.header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
}

.license-text textarea {
  font-family: monospace;
  font-size: small;
  line-height: unset;
}

.footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.header {
  background: white;
}

.vm-list {
  display: flex;
}

.wrap {
  width: 100%;
}

.app-body {
  background: var(--light);
  height: 100%;
  min-height: calc(100vh - 70px);
}

.link {
  color: var(--main);
}

.centered td {
  vertical-align: middle;
}

td.active {
  cursor: pointer;
}

.status-column {
  text-align: center;
}
.nobg {
  background-color: transparent !important;
  border: thin solid rgba(0, 0, 0, 0.12);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.active {
  text-decoration: underline;
  color: var(--info);
}

.lighter {
  font-weight: lighter;
}

.text-btn {
  text-transform: none;
}

.modal-heading {
  height: 44px;
}
</style>