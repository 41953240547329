import Vue from "vue";

const apiUrl = process.env.BACKEND_URL;

export default {
  async getLicensionsList() {
    const token = Vue.$cookies.get("token");
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(`${apiUrl}lic/v1/lics`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  },
  async getLicenseInfo(id) {
    const token = Vue.$cookies.get("token");
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(`${apiUrl}lic/v1/lics/${id}.lic`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  },

  async login(data) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    const body = {
      login: data.username,
      password: data.password,
      auth_method: "database",
    };
    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    };

    return fetch(`${apiUrl}id/login`, requestOptions)
      .catch((error) => console.log("error", error))
      .then((response) => response.json());
  },
  async logout() {},
  async register(data) {
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(`${apiUrl}id/users`, requestOptions).then((response) =>
      response.json()
    );
  },
  async getCompanyInfo(id) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(`${apiUrl}company_info/v1/company/${id}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  },
  async createLic(data) {
    const token = Vue.$cookies.get("token");
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    return fetch(`${apiUrl}lic/v1/lics`, requestOptions).then((response) =>
      response.json()
    );
  },
  async sendPasswordReset(data) {
    const token = Vue.$cookies.get("token");
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    return fetch(`${apiUrl}id/login/password_reset`, requestOptions).then(
      (response) => response.json()
    );
  },
  async recoverPasswordWithCode(data) {
    const token = Vue.$cookies.get("token");
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    return fetch(`${apiUrl}id/login/password_reset`, requestOptions).then(
      (response) => response.json()
    );
  },
};
